import React, {useState} from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  withMobileDialog,
  withStyles,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import MailOutlined from '@material-ui/icons/MailOutlined';

const styles = () =>
  createStyles({
    dialogContent: {
      paddingTop: 16,
    },
  });

const Transition = props => <Slide direction="up" {...props} />;

const SendToEmail = ({
                       onSubmit,
                       classes,
                       fullScreen,
                       disabled,
                       buttonLabel,
                     }) => {

  const [dialogOpened, setDialogOpened] = useState(false);
  const [email, setEmail] = useState('');

  const openDialog = () => {
    setDialogOpened(true);
  };

  const closeDialog = () => {
    setDialogOpened(false);
  };

  const onSubmitLocal = () => {
    if (onSubmit) {
      onSubmit(email);
    }
    closeDialog();
  };

  return (
    <>
      <Button
        onClick={e => {
          e.stopPropagation();
          openDialog();
        }}
        size="small"
        variant="text"
        style={{textTransform: 'none', lineHeight: '14px'}}
        disabled={disabled}
      >
        <MailOutlined
          style={{
            marginRight: 16,
          }}
        />
        {buttonLabel || 'Отправить на почту'}
      </Button>
      <Dialog
        open={dialogOpened}
        maxWidth="md"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        onClick={e => e.stopPropagation()}
      >
        <DialogTitle label="Отправить на Email"/>
        <DialogContent className={classes.dialogContent} onClick={() => console.log('@@@@@@')}>
          <Grid container wrap="wrap" spacing={1} alignContent="flex-end">
            <Grid item xs={12}>
              <TextField
                label="E-mail"
                onChange={e => setEmail(e.target.value)}
                onClick={e => e.stopPropagation()}
                name="email"
                value={email}
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  onSubmitLocal();
                }}
                color="primary"
                variant="contained"
                fullWidth
              >
                Отправить
                {'  '}
                <SendIcon/>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(
  withMobileDialog({breakpoint: 'sm'})(SendToEmail)
);
