import React from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core'
import {BrowserRouter, HashRouter} from 'react-router-dom';
import Widget from './Widget/Widget';
import './App.css';

const Router = process.env.NODE_ENV === 'production' ? BrowserRouter : HashRouter;

const App = () => (
  <MuiThemeProvider
    theme={createMuiTheme({
      palette: {
        primary: {
          light: '#5db8ff',
          main: '#0088ce',
          dark: '#005b9d',
          contrastText: '#ededed',
        },
        secondary: {
          main: '#e51b1b',
          contrastText: '#000000',
        },
        type: 'light',
      },
      typography: {
        // Use the system font.
        fontFamily: '"Helvetica Neue", "Open Sans","Segoe UI",Roboto,Arial,sans-serif',
      },
    })}
  >
    <Router>
      <Widget/>
    </Router>
  </MuiThemeProvider>
);

export default App;
