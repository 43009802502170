import React from 'react';
import {createStyles, Dialog, DialogContent, DialogContentText, Grid, withStyles,} from '@material-ui/core';
import './spinner.css';

const styles = () =>
  createStyles({
    spinnerContainer: {
      overflow: 'hidden',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    text: {
      color: 'white',
    },
  });


const Spinner = ({classes, ...dialogProps}) => (
  <Dialog open {...dialogProps} PaperProps={{className: classes.spinnerContainer, elevation: 0}}>
    <DialogContent className={classes.spinnerContainer}>
      <Grid container direction="column" alignItems="center" spacing={16}>
        <Grid item>
          <DialogContentText className={classes.text}>Загрузка, пожалуйста подождите...</DialogContentText>
        </Grid>
        <Grid item style={{minHeight: 100, paddingTop: 50}}>
          <div className="flex-container">
            <div className="unit">
              <div className="heart">
                <div className="heart-piece-0"/>
                <div className="heart-piece-1"/>
                <div className="heart-piece-2"/>
                <div className="heart-piece-3"/>
                <div className="heart-piece-4"/>
                <div className="heart-piece-5"/>
                <div className="heart-piece-6"/>
                <div className="heart-piece-7"/>
                <div className="heart-piece-8"/>
              </div>
            </div>
          </div>
          {/*<CircularProgress className={classes.text} />*/}
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(Spinner);
