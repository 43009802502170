import React, {useState} from 'react';
import {Button, createStyles, Grid, LinearProgress, TextField, Typography, withStyles,} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import SendToEmail from '../commons/SendToEmail';
import {parse} from 'query-string';
import MobileDetect from 'mobile-detect';
import {stringify} from 'querystring';
import Spinner from '../Spinner/Spinner';

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();

const formatLastName = value => value.toLowerCase().replace(/(^|[^а-я\u0451])([а-я\u0451])/g, (_, g1, g2) => g1 + g2.toUpperCase());

const styles = () =>
  createStyles({
    linearColorPrimary: {
      backgroundColor: '#ff1200',
    },
    linearBarColorPrimary: {
      backgroundColor: '#4caf50',
    },
  });

const url = process.env.URL || 'https://pat.vitaclinica.ru:55555/';


const getQueryString = obj => {
  const queryObject = {};
  // eslint-disable-next-line array-callback-return
  Object.entries(obj).map(([key, value]) => {
    if (value) {
      queryObject[key] = value;
    }
  });

  return `?${stringify(queryObject)}`;
};

const GetAnalyses = ({classes, location}) => {
  const barcode = parse(location.search).barcode || '';
  const name = parse(location.search).lastName || '';
  const source = parse(location.search).source || '';

  const [data, setData] = useState(null);
  const [textBarcode, setBarcode] = useState(barcode);
  const [lastName, setLastName] = useState(name);
  const [errorVisible, setErrorVisible] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  if (source === 'celdi') {
    document.title = 'Результаты анализов online';
  }

  const sent2Email = email => {
    fetch(
      `${url}external/sendPDF`,
      {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          barcode: textBarcode,
          lastName: formatLastName(lastName),
        })
      }).then(response => {
      console.error(response.message || response.message);
    });
  };

  const getAnalyse = async () => {
    setSpinnerVisible(true);
    const params = getQueryString({barcode: textBarcode, fullName: formatLastName(lastName)});

    const response = await fetch(`${url}external/laboratoryAnalyzes${params}`, {
      method: 'get', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      setSpinnerVisible(false);
      setErrorVisible(true);
      return null;
    }

    const json = await response.json();
    const {data: analyses} = json;
    setData(analyses);
    setSpinnerVisible(false)
  };

  const handleChange = e => {
    setErrorVisible(false);
    const value = e.target.value;
    setBarcode(value);
    setData(null);
  };

  // 2149568742
  // панёва
  // 2117499450
  // Ровкин

  const handleLastNameChange = e => {
    setErrorVisible(false);
    const value = e.target.value;
    setLastName(value);
    setData(null);
  };

  const getStatuses = analyses => analyses.analyzes.filter(it => !it.isSet).map(it => it.approvalDateResult);
  const calcPercentage = statuses => {
    let readyCount = 0;

    statuses.forEach(it => {
      if (it) {
        readyCount += 1;
      }
    });

    return readyCount / statuses.length;
  };

  const renderAnalysesProgress = () => {
    const statuses = getStatuses(data);
    const percentage = calcPercentage(statuses) * 100;
    return (
      <>
        <Typography variant="caption">{percentage === 100 ? 'Готов' : 'В работе'}</Typography>
        <LinearProgress
          variant="determinate"
          value={percentage}
          style={{width: '100%', minWidth: 245}}
          classes={{
            colorPrimary: classes.linearColorPrimary,
            barColorPrimary: classes.linearBarColorPrimary,
          }}
        />
      </>
    );
  };

  return (
    <>
      <Grid container spacing={1} style={{textAlign: 'center'}}>
        <Grid item xs={undefined} sm={3} md={4}/>
        <Grid item xs={12} sm={6} md={4}>
          {/*<Paper style={{ padding: '10px 10px 30px 10px' }} square> */}
          <Grid container spacing={1}>
            {source === 'celdi' && (
              <>
                <Grid item xs={12} style={{height: isMobile ? 50 : 100}}/>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <img
                    className="w-128 m-32"
                    src="https://b2b.celdi.ru/assets/images/logos/celdi_logo1.png"
                    alt="logo"
                  />
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <h3>Просмотр результатов исследований</h3>
                  <h4 style={{margin: 6}}>Пожалуйста, заполните поля</h4>
                  <Typography variant="caption">
                    <a href="https://celdi.ru/lk/" target="_blank" rel="noopener noreferrer">
                      Инструкция по использованию формы запроса на получение результатов исследований онлайн
                    </a>
                  </Typography>
                </Grid>
              </>
            )}
            {errorVisible && (
              <Grid item xs={12}>
                <Typography variant="caption" color="secondary">
                  Данные не найдены! Проверьте корректность ввода.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField label="Штрихкод" variant="outlined" value={textBarcode} onChange={handleChange} fullWidth/>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Фамилия"
                variant="outlined"
                value={lastName}
                onChange={handleLastNameChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={getAnalyse}
                color="primary"
                target="_blank"
                rel="noopener noreferrer"
                size="small"
                variant="contained"
                disabled={!textBarcode || !lastName}
                style={{textTransform: 'none'}}
                fullWidth
              >
                Проверить
              </Button>
            </Grid>
            <Grid item xs={12} style={{padding: 0}}>
              <Typography variant="caption">
                Нажимая кнопку, Вы соглашаетесь с{' '}
                <a href="https://celdi.ru/privacy/" target="_blank" rel="noopener noreferrer">
                  Политикой в отношении персональных данных
                </a>
                .
              </Typography>
            </Grid>
            {data && !data.paid && (
              <Grid item xs={12}>
                <Typography variant="caption" color="secondary">
                  Не получена полная оплата!
                </Typography>
              </Grid>
            )}
            {data && data.paid && (
              <Grid container spacing={1}>
                <Grid item xs={12} alignItems="center">
                  <Typography variant="caption">Статус анализа: </Typography>
                  {renderAnalysesProgress()}
                </Grid>
              </Grid>
            )}
            {data && data.PDFAvailable && data.paid && (
              <>
                <Grid item xs={6}>
                  <Button
                    href={`${url}external/getPDF?barcode=${textBarcode}&lastName=${formatLastName(lastName)}`}
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                    variant="text"
                    disabled={!textBarcode || !lastName}
                    style={{textTransform: 'none'}}
                    fullWidth
                  >
                    <AssignmentIcon
                      style={{
                        marginRight: 16,
                      }}
                    />
                    Посмотреть
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <SendToEmail onSubmit={sent2Email} disabled={!textBarcode || !lastName}/>
                </Grid>
              </>
            )}
          </Grid>
          {/*} </Paper> */}
        </Grid>
      </Grid>
      {spinnerVisible && <Spinner/>}
    </>
  );
};
// 2155840062

export default withStyles(styles)(GetAnalyses);
