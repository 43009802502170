import React from 'react';
import {Typography} from '@material-ui/core';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import GetAnalyses from './GetAnalyses';
import {analysesRoute, appointmentRoute} from './routes';

const renderDevPage = () => <Typography variant="h6">Страница в разработке</Typography>;

const Widget = () => (
  <Switch>
    <Route path={analysesRoute.path} component={GetAnalyses}/>
    <Route path={appointmentRoute.path} render={renderDevPage}/>
    <Redirect to={analysesRoute.path}/>
  </Switch>
);

export default withRouter(Widget);
